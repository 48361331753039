<template lang="">
  <div>
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-4 md:grid-cols-2">
          <TitlePlus
            :title="
              $t(
                'components.marketPlaceManagement.orders.details.marketplace.steps.buyers.headline'
              )
            "
            :hide-plus="true"
          />
          <div class="md:flex md:justify-end">
            <DateRangePicker
              :time-filter-enabled="false"
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-2 my-1 md:items-center md:flex-row px-5">
        <SummaryCard
          :title="
            $t('components.marketPlaceManagement.buyers.summary.totalBuyers')
          "
          :value="indexMetaData.summary.total_number_of_buyers"
          variant="gray"
        />
        <SummaryCard
          :title="
            $t(
              'components.marketPlaceManagement.buyers.summary.totalAssignedVehicles'
            )
          "
          :value="indexMetaData.summary.total_assigned_vehicles"
          variant="gray"
        />
        <SummaryCard
          :title="
            $t(
              'components.marketPlaceManagement.buyers.summary.totalPurchasedVehicles'
            )
          "
          :value="indexMetaData.summary.total_purchased_vehicles"
          variant="gray"
        />
        <SummaryCard
          :title="
            $t(
              'components.marketPlaceManagement.buyers.summary.totalPurchasedVehiclesAmount'
            )
          "
          :value="indexMetaData.summary.total_purchased_vehicles_value"
          :symbol="indexMetaData.currency.symbol"
          variant="gray"
        />
        <SummaryCard
          :title="
            $t('components.marketPlaceManagement.buyers.summary.totalRevenue')
          "
          :value="indexMetaData.summary.total_revenue"
          :symbol="indexMetaData.currency.symbol"
          variant="gray"
        />
        <SummaryCard
          :title="
            $t('components.marketPlaceManagement.buyers.summary.totalPayout')
          "
          :value="indexMetaData.summary.total_payout"
          :symbol="indexMetaData.currency.symbol"
          variant="gray"
        />
      </div>

      <FSTable
        :fst-id="`ViewMarketplaceBuyers`"
        :searchEnabled="false"
        :headers="getTableHeaders"
        :endpoint="getEndpoint"
        :qso="getEndpointOptions"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex" text-fallback-always>
                <FSTableRowItem
                  :text="item.full_name"
                  :to="{
                    name: 'ViewBuyerProfile',
                    params: { id: item.id, redirectPath: path },
                  }"
                />
                <FSTableRowItem :text="item.email" />
                <FSTableRowItem :text="item.last_login" :date="true" />
                <FSTableRowItem :text="item.last_purchased_at" :date="true" />
                <FSTableRowItem>
                  <template v-if="item.payment_method.length > 0">
                    <i
                      :class="getPaymentCardIconClass(item.payment_method[0])"
                    />
                    <span>&nbsp;&nbsp;</span>
                    {{
                      `${item.payment_method[0].brand}**${item.payment_method[0].detail}`
                    }}
                  </template>
                  <template v-else><span>--</span></template>
                </FSTableRowItem>

                <FSTableRowItem
                  :text="
                    `${item.total_assigned_vehicles}/${item.number_of_vehicle_purchased}`
                  "
                />
                <FSTableRowItem
                  :text="
                    `${indexMetaData.currency.symbol} ${item.purchased_value}`
                  "
                />
                <FSTableRowItem
                  :text="
                    `${indexMetaData.currency.symbol} ${item.lifetime_revenue}`
                  "
                />
                <FSTableRowItem
                  :text="
                    `${indexMetaData.currency.symbol} ${item.available_funds}`
                  "
                />

                <FSTableRowItem>
                  <BuyerActivityStatusAction
                    :id="itemIndex"
                    :data="item"
                    @force-sync="$store.dispatch('fsTable/fetchData')"
                  />
                </FSTableRowItem>
                <FSTableRowItem>
                  <AvailableFundsTransfer
                    :id="itemIndex"
                    :data="item"
                    @force-sync="$store.dispatch('fsTable/fetchData')"
                  />
                </FSTableRowItem>
              </FSTableRow>
            </template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem
                  :text="item.full_name"
                  :to="{
                    name: 'ViewBuyerProfile',
                    params: { id: item.id, redirectPath: path },
                  }"
                />
                <FSTableRowItem
                  :text="
                    `${indexMetaData.currency.symbol} ${item.available_funds}`
                  "
                />
                <FSTableRowItem>
                  <BuyerActivityStatusAction
                    :id="itemIndex"
                    :data="item"
                    @force-sync="$store.dispatch('fsTable/fetchData')"
                  />
                </FSTableRowItem>
                <FSTableRowItem>
                  <AvailableFundsTransfer
                    :id="itemIndex"
                    :data="item"
                    @force-sync="$store.dispatch('fsTable/fetchData')"
                  />
                </FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.details.marketplace.steps.buyers.table.columns.email'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.email || '--' }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.details.marketplace.steps.buyers.table.columns.lastLoginAt'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{
                        getFormattedDateTime(
                          item.last_login,
                          'D MMM YYYY, h:mm:ss a'
                        )
                      }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.details.marketplace.steps.buyers.table.columns.lastPurchasedAt'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{
                        getFormattedDateTime(
                          item.last_purchased_at,
                          'D MMM YYYY, h:mm:ss a'
                        )
                      }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.details.marketplace.steps.buyers.table.columns.paymentMethod'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <template v-if="item.payment_method.length > 0">
                        <i
                          :class="
                            getPaymentCardIconClass(item.payment_method[0])
                          "
                        />
                        <span>&nbsp;&nbsp;</span>
                        {{
                          `${item.payment_method[0].brand}**${item.payment_method[0].detail}`
                        }}
                      </template>
                      <template v-else><span>--</span></template>
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.details.marketplace.steps.buyers.table.columns.Assigned/PurchasedVehicles'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{
                        `${item.total_assigned_vehicles}/${item.number_of_vehicle_purchased}`
                      }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.details.marketplace.steps.buyers.table.columns.purchasedValue'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{
                        `${indexMetaData.currency.symbol} ${item.purchased_value}`
                      }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.details.marketplace.steps.buyers.table.columns.lifetimeRevenue'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{
                        `${indexMetaData.currency.symbol} ${item.lifetime_revenue}`
                      }}
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
    </content-section>
  </div>
</template>
<script>
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import { getFormattedDateTime } from '@/utils/datetime'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  onApplyFilterDateRange,
} from '@/components/fs-table'
import { MarketplaceConfig } from '@/config/MarketplaceConfig'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'

export default {
  name: 'ViewMarketplaceBuyers',
  components: {
    SummaryCard: () => import('@/components/cards/SummaryCard'),
    BuyerActivityStatusAction: () =>
      import('@/views/marketplace/modals/BuyerActivityStatusAction.vue'),
    AvailableFundsTransfer: () =>
      import('@/views/marketplace/modals/AvailableFundsTransfer.vue'),
    DateRangePicker,
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
  },
  data() {
    return {
      indexMetaData: {
        count: {
          total: 0,
        },
        currency: {
          symbol: '',
        },
        summary: {
          total_number_of_buyers: 0,
          total_assigned_vehicles: 0,
          total_purchased_vehicles: 0,
          total_purchased_vehicles_value: 0,
          total_revenue: 0,
          total_payout: 0,
        },
      },
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.buyers.table.columns.name'
          ),
          width: '10%',
          sort: 'id',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.buyers.table.columns.email'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.buyers.table.columns.lastLoginAt'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.buyers.table.columns.lastPurchasedAt'
          ),
          width: '12%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.buyers.table.columns.paymentMethod'
          ),
          width: '12%',
          sort: 'null',
        },

        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.buyers.table.columns.Assigned/PurchasedVehicles'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.buyers.table.columns.purchasedValue'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.buyers.table.columns.lifetimeRevenue'
          ),
          width: '5%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.buyers.table.columns.availableFund'
          ),
          width: '5%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.buyers.table.columns.status'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.buyers.table.columns.actions'
          ),
          width: '5%',
          sort: 'null',
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.buyers.table.columns.name'
          ),
          width: '30%',
          sort: null,
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.buyers.table.columns.availableFund'
          ),
          width: '20%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.buyers.table.columns.status'
          ),
          width: '20%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.buyers.table.columns.actions'
          ),
          width: '10%',
          sort: 'null',
        },
      ],
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    marketplaceId() {
      return this.$route.params.id
    },
    getEndpoint() {
      return MarketplaceConfig.api.marketplaceBuyerList(this.marketplaceId)
    },
    getEndpointOptions() {
      return {
        prepend: '&',
        append: '',
      }
    },
  },

  methods: {
    onApplyFilterDateRange,
    getFormattedDateTime,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    getPaymentCardIconClass(card) {
      const brand = card?.brand?.toLowerCase() || ''
      console.log('getPaymentCardIconClass', brand)
      let iconClass = 'fab '
      switch (brand?.toLowerCase()) {
        case 'visa':
          iconClass += 'fa-cc-visa'
          break
        case 'mastercard':
          iconClass += 'fa-cc-mastercard'
          break
        case 'americanexpress':
          iconClass += 'fa-cc-amex'
          break
        case 'discover':
          iconClass += 'fa-cc-discover'
          break
        case 'jcb':
          iconClass += 'fa-cc-jcb'
          break
        case 'dinersclub':
          iconClass += 'fa-cc-diners-club'
          break
        default:
          iconClass = 'fas fa-wallet'
          break
      }
      return iconClass
    },
  },
}
</script>
<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
